
import axios from 'axios'

function SygrisAPI(host, token) {
    this.host = host;
    this.access_token = token
    this.access_token_expiry = null
    this.transport = axios.create({
        withCredentials: true,
    })
}


SygrisAPI.prototype._post = async function (endpoint, data) {
    const url = this.host + endpoint;
    console.log("Posting to: ", url, data);
    const headers = {}
    if (this.access_token) {
        headers['Authorization'] = `Bearer ${this.access_token}`;
    }
    try {
        return await this.transport.post(url, data, { headers: headers });
    } catch (error) {
        throw Error(error);
    }
}
SygrisAPI.prototype._get = async function (endpoint) {
    const url = this.host + endpoint;
    console.log("Getting from: ", url);
    const headers = {}
    if (this.access_token) {
        headers['Authorization'] = `Bearer ${this.access_token}`;
    }
    try {
        return await this.transport.get(url, { headers: headers });
    } catch (error) {
        throw Error(error);
    }
}


SygrisAPI.prototype.login = async function () {
    const response = await this._post('/api/user/users/login', { Username: this.username, Password: this.password })
    this.access_token = response.data.access_token;
    this.access_token_expiry = response.data.access_token_expiry;
    return response.data;
}

SygrisAPI.prototype.collectionsDataAdd = function (data) {
    const endpoint = "/api/collections/collections_data/add";
    return this._post(endpoint, data);
}

SygrisAPI.prototype.collectionsDataInsertOrUpdate = function (data) {
    const endpoint = "/api/collections/collections_data/insert_or_update_on_duplicated";
    return this._post(endpoint, data);
}

SygrisAPI.prototype.collectionsDatatypes = function () {
    const endpoint = "/api/collections/datatypes";
    return this._get(`${endpoint}/`);
}


SygrisAPI.prototype.queryFetch = function (queryId, conditions, limit) {
    const endpoint = "/api/collections/queries/fetch";
    let query = `${endpoint}/${queryId}`;
    if (conditions) {
        query = `${query}/${conditions}`
    }
    if (limit) {
        query = `${query}/${limit}`
    }
    return this._get(query);
}



export {SygrisAPI};