import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

export default function LanguageMenu({ initialLang, changeLanguageCallback }) {
    console.log('refresh', initialLang);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentLang, setCurrentLang] = React.useState(initialLang);
    
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (lang) => {
        setCurrentLang(lang);
        window.lang = lang;
        setAnchorEl(null);
        changeLanguageCallback(lang);
    };

    return (
        <>
            <Button aria-controls="simple-menu" aria-haspopup="true" style={{ color: 'white' }} onClick={handleClick}>
                {currentLang === 'por' && <>Português</>}
                {currentLang === 'spa' && <>Español</>}
                {currentLang === 'eng' && <>English</>}
            </Button>
            <Menu keepMounted id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => { handleClose('eng') }}>English</MenuItem>
                <MenuItem onClick={() => { handleClose('spa') }}>Español</MenuItem>
                <MenuItem onClick={() => { handleClose('por') }}>Português</MenuItem>
            </Menu>
        </>
    )
}