import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Input from "./Input";
import Test from "./Test";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';


function App() {

  const { ready } = useTranslation();

  return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Router>
            {ready &&  <Route path="/input" component={Input} /> }
            {ready &&  <Route path="/test" component={Test} /> }
        </Router>
      </Grid>
  );
}

export default App;
