import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios'

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

import CustomTooltip from './components/CustomTooltip'
import CustomTooltip2 from './components/CustomTooltip2'
import LanguageMenu from './components/LanguageMenu'
import InfoIcon from '@material-ui/icons/InfoOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';
// import IE from 'ie-version';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIosIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDownOutlined'
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


import CircularProgress from '@material-ui/core/CircularProgress';


import logoMapfre from './resources/logo-mapfre.png';
import logoSygris from './resources/logo-sygris.png';

import { SygrisAPI } from './lib/SygrisAPI';


const queryString = require('query-string');

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#F5F4F5',
      color: '#D62118',
    },
    body: {
      fontSize: 12,
      padding: '8px',
      '&.ORO': {
        color: '#FDD73F',
        fontWeight: 'bold'
      },
      '&.PLATA': {
        color: '#C0C4CD',
        fontWeight: 'bold'
      },
      '&.BRONCE': {
        color: '#8B7855',
        fontWeight: 'bold'
      }

    },
  }))(TableCell);

  const StyledTableRow = withStyles(theme => ({
    root: {
      backgroundColor: 'white'
      /*
      '&:nth-of-type(even)': {
        backgroundColor: '#F2F2F2',
      },
      '&:nth-of-type(odd)': {
        backgroundColor: '#F0FBFF',
      },
      */
    },
  }))(TableRow);

  const StyledSelect = withStyles(theme => ({
    root: {
      color: 'red',
      fontSize: '14px',
      textAlign: 'center'
    },
    select: {
      textAlign: 'center'
    },
    selectMenu: {
      textAlign: 'center'
    },
  }))(Select)

  const useStyles = makeStyles(theme => ({
    underline: {
      '&:hover:not($disabled):before': {
        borderBottomColor: 'red !important'
      },
      '&:active:not($disabled)': {
        borderBottomColor: 'red !important'
      },
      '&:focus:not($disabled)': {
        borderBottomColor: 'red !important'
      },
    },
    grow: {
      flexGrow: 1,
    },
    root: {
      width: '100%',
      overflowX: 'auto',
      marginTop: '84px'
    },
    tableHead2Row: {
      top: 0,
      backgroundColor: 'white'
    },
    appBar: {
      boxShadow: 'none',
      backgroundColor: 'white'
    },
    mainToolbar: {
      backgroundColor: '#D62118',
      color: theme.palette.common.white,
      paddingLeft: '19px'
    },
    secondaryToolbar: {
      backgroundColor: theme.palette.common.white,
      color: '#161616',
      minHeight: '36px',
      lineHeight: '36px'
    },
    red: {
      color: '#D62118'
    },
    tableWrapper: {
    },
    responsiveImage: {
      height: '30px'
    },
    responsiveImage2: {
      paddingLeft: '10px',
      height: '20px'
    },
    lateralMenu: {
      paddingTop: '0px'
    },
    lateralMenuItem: {
      "&.Mui-selected": {
        backgroundColor: '#D62118',
        color: 'white'
      }
    },
    tableHead: function () {
      /*
      if (IE && IE.version === 11) {
        return {
          position: 'fixed !important',
          top: '14px',
          zIndex: 10
        }  
      } else {
        return {}
      }
      */
    },
    table: {
      minWidth: 700,
      /*
      paddingTop: function () {
        return IE && IE.version === 11 ? '99px' : null
      }
      */
    },
    formControl: {
      margin: 1,
      minWidth: 80
    },
    select: {
      color: 'red',
      '&:before': {
          borderColor: 'green',
      },
      '&:after': {
          borderColor: 'green',
      }
  },  
  }));
  
  

function Input({ match, location, history }) {
  const classes = useStyles();
  const { t, ready, i18n } = useTranslation();

  const params = queryString.parse(location.search);
  const token = params.token;
  const appid = params.app;
  const lang = params.lang;
  const [selectedIndex, setSelectedIndex] = useState(appid ? parseInt(appid) : 0);
  // const userid = parseInt(token.slice(5).slice(0,-5));

  const [userid] = useState(parseInt(token.slice(5).slice(0,-5)));
  // const [users, setUsers] = useState([]);
  const [first, setFirst] = useState(true);
  const [updating, setUpdating] = useState(true);
  const [allUserSolutions, setAllUserSolutions] = useState([]);
  const [solutionsDescriptions, setSolutionsDescriptions] = useState({});
  const [solutionsStatus, setSolutionsStatus] = useState({});
  const [filtered, setFiltered] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [results, setResults] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [open, setOpen] = useState(false);
  const [surveyClosed, setSurveyClosed] = useState(false);


  useEffect(() => {
    async function getData() {
      setUpdating(true);
      const api = new SygrisAPI("https://mapfretech.sygris.com", "214af0f068a9eff68e240ac7627a2fce74e6e241");
      try {
        const usersQuery = await api.queryFetch(44286, "[49889].[ID]=" + userid, 10000);
        let descriptionsQuery
        // descriptionsQuery = await axios({ method: 'POST', url: '/.netlify/functions/get', data: {queryId: 49062}})
        descriptionsQuery = await api.queryFetch(49062);
        
        // setUsers(usersQuery.data.data);
        let _allUserSolutions = []
        let _solutionsDescriptions = {}
        let _solutionsStatus = {}
        usersQuery.data.data.forEach(function (item, itemIndex) {
          if (!_solutionsDescriptions[item['solucion']]) {
            _solutionsDescriptions[item['solucion']] = {};
            _solutionsStatus[item['solucion']] = item['estado_id'];
          }
          _solutionsDescriptions[item['solucion']][item['idioma']] = item['descripcion_soluciones'];
          
          if (item.id_user === userid && _allUserSolutions.indexOf(item['solucion']) === -1) {      
            _allUserSolutions.push(item['solucion'])
          }
        })
        
        let filterLang = window.lang || usersQuery.data.data[0]["idioma"];
        if (filterLang === "por") filterLang = "spa";

        let _filtered = usersQuery.data.data.filter(function (item) {
          return item.id_user === userid && item["solucion"] === _allUserSolutions[selectedIndex] && item.idioma_procesos === filterLang;
        })
        let _mainData = {};

        if (_filtered && _filtered.length > 0 ) {
          _mainData.year = _filtered[0]["ano"];
          _mainData.company = _filtered[0]["compania"];
          _mainData.region = _filtered[0]["subregion_id"];
          _mainData.country = _filtered[0]["pais"];
          _mainData.lang = window.lang || _filtered[0]["idioma"];
          _mainData.origLang = _filtered[0]["idioma"];
          _mainData.solution = _filtered[0]["solucion"];
          if (first) {
            if (lang) {
              _mainData.lang = lang;
              window.lang = lang;
            }
            changeLanguage(_mainData.lang);
            setFirst(false);
          } 
        }

        _filtered.sort(function (a,b) {
          if (a.macroproceso_mostrar > b.macroproceso_mostrar) return 1;
          if (a.macroproceso_mostrar < b.macroproceso_mostrar) return -1;
          return 0;
        });
        
        let _results = {}
         _filtered.forEach(function(item) {
            const newNoVida = {
              Field_6564: item.id_user,
              Field_6847: 2,
              Field_6587: item.id_ano_id,
              Field_6586: item.id_compania,
              Field_6588: item.id_subregion_id,
              Field_6585: item.id_pais_id,
              Field_6584: item.id_solucion_id,
              Field_6567: item.id_macroproceso_id,
              Field_6568: item.id_cdv_id,
              Field_6565: 2,
              Field_6566: item.puntuacionnovida,
              Field_6849: item.sharedidnovida,
            }
            const newVida = {
              Field_6564: item.id_user,
              Field_6847: 2,
              Field_6587: item.id_ano_id,
              Field_6586: item.id_compania,
              Field_6588: item.id_subregion_id,
              Field_6585: item.id_pais_id,
              Field_6584: item.id_solucion_id,
              Field_6567: item.id_macroproceso_id,
              Field_6568: item.id_cdv_id,
              Field_6565: 1,
              Field_6566: item.puntuacionvida,
              Field_6849: item.sharedidvida,
            }
            const newSalud = {
              Field_6564: item.id_user,
              Field_6847: 2,
              Field_6587: item.id_ano_id,
              Field_6586: item.id_compania,
              Field_6588: item.id_subregion_id,
              Field_6585: item.id_pais_id,
              Field_6584: item.id_solucion_id,
              Field_6567: item.id_macroproceso_id,
              Field_6568: item.id_cdv_id,
              Field_6565: 3,
              Field_6566: item.puntuacionsalud,
              Field_6849: item.sharedidsalud,
            }
            _results[item.sharedidvida] = newVida;
            _results[item.sharedidnovida] = newNoVida;
            _results[item.sharedidsalud] = newSalud;
        })
        setAllUserSolutions(JSON.parse(JSON.stringify(_allUserSolutions)));
        setSolutionsStatus(JSON.parse(JSON.stringify(_solutionsStatus)));
        setSolutionsDescriptions(JSON.parse(JSON.stringify(_solutionsDescriptions)));
        setMainData(JSON.parse(JSON.stringify(_mainData)));
        setResults(JSON.parse(JSON.stringify(_results)))
        setFiltered(JSON.parse(JSON.stringify(_filtered)));
        setDescriptions(JSON.parse(JSON.stringify(descriptionsQuery.data.data)));

        if(parseInt(_solutionsStatus[_allUserSolutions[selectedIndex]]) !== 3) {
          setReadOnly(false);
        } else {
          setReadOnly(true);
        }

        if(_filtered.length > 0 && _filtered[0].bloquear_encuesta && parseInt(_filtered[0].bloquear_encuesta) === 1) {
          setReadOnly(true);
          setSurveyClosed(true);
        } else {
          setSurveyClosed(false);
        }

        setUpdating(false);
          setTimeout( () => { 
            const child = document.getElementById('solution-' + selectedIndex);
            if (child) {
              document.getElementById('solutionsList').scrollTop = (child.offsetHeight * selectedIndex)
            }
          }, 100);
      } catch (e) {
        console.log('Error requesting to API', e);
      }
    }
    getData();
  }, [userid, selectedIndex, window.lang]);

  const handleListItemClick = (event, index) => {
    console.log('clicked', index);
    setSelectedIndex(index);
  };


  const changeLanguage = function (lang) {
    i18n.changeLanguage(lang);
  }

  const handleResultChange = (index, row, tipo) => event => {
    const updatedResults = {...results};
    updatedResults[row['Field_6849']]['Field_6566'] = parseInt(event.target.value);
    setResults(updatedResults);
  }

  const getDescription = function (key, lang, isMacro) {
    if ( lang === "por") lang = "spa";
    const result =  descriptions.find(function (item) {
      // console.log(item, key, item.macroproceso_id ,item.cadena_valor_lv1_id  );
      if (isMacro === true) {
        return item.language === lang && item.macroproceso_id === key;
      }
      return item.language === lang && item.cadena_valor_lv1_id === key;
    });
    if (result) return result['descripcion'];
    return null;
  }

  const getStatusDescriptionLabel = function (status) {
    status = parseInt(status);
    if (status === 1) return t("Sin Enviar");
    if (status === 2) return t("Guardada");
    return t("Enviada");
  }

  const getSolutionDescription = function (solution, lang) {
    // if (lang === "por") lang ="spa";
    return solutionsDescriptions[solution][lang];
  }

  const handleSaveClick = async function (estado) {
    
    const request = {
      collection_oid: 48634,
      data: Object.values(results).map(function (item) {
        item.Field_6847 = estado;
        return item;
      })
    }

    // console.log('req', JSON.stringify(request));
    const api = new SygrisAPI("https://mapfretech.sygris.com", "214af0f068a9eff68e240ac7627a2fce74e6e241");
    // setUpdating(true);
    await api.collectionsDataInsertOrUpdate(request);
    // await axios({ method: 'POST', url: '/.netlify/functions/submit', data: request })

    window.location = `/input?token=${token}&app=${selectedIndex}&lang=${mainData.lang}`
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (save) => {
    if (save) handleSaveClick(3);
    setOpen(false);
  };

  if(updating) return (
    <CircularProgress />
  )

  return (
    (ready && !updating && !first &&  <>
      <Grid container direction="row" justify="center" alignItems="center" style={{backgroundColor: 'grey', height: '100vh'}}>        
        <Grid item xs={12}>
          <Toolbar variant="dense" className={classes.mainToolbar}>
            <img src={logoSygris}  alt="Sygris" className={classes.responsiveImage}  ></img> | <img src={logoMapfre} alt="Mapfre" className={classes.responsiveImage2}></img>
            <div className={classes.grow}></div>
            { mainData.lang && <LanguageMenu initialLang={mainData.lang} changeLanguageCallback={changeLanguage} />}
          </Toolbar>
          <Toolbar variant="dense" className={classes.secondaryToolbar}>
            <Typography gutterBottom variant="subtitle2" component="span" style={{margin: 0}}>{mainData.year}&nbsp;&nbsp;|&nbsp;&nbsp;{mainData.company} / {mainData.region} / {mainData.country} <span className={classes.red}>/ { mainData.solution }</span></Typography>
          </Toolbar>
        </Grid>
        <Grid item xs={12} style={{ borderTop: '1px solid rgb(224,224,224)', backgroundColor: 'white'}}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <StyledTableCell>   
                <Typography gutterBottom variant="subtitle2" component="span" style={{margin: 0, color: '#D62118'}}>{t('Indique')}<CustomTooltip><InfoIcon fontSize="small" style={{ top: '6px', left: '6px', position: 'relative' }} /></CustomTooltip></Typography>               
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: '100px', color: '#D62118'}}> 
                  <strong>{t('NO VIDA')}</strong>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: '100px', color: '#D62118'}}>                  
                  <strong>{t('VIDA')}</strong>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: '100px', color: '#D62118'}}> 
                  <strong>{t('SALUD')}</strong>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <Grid id="solutionsList" item xs={5} style={{borderRight: '1px solid rgb(224,224,224)', borderTop: '1px solid rgb(224,224,224)', backgroundColor: 'white', height: '100%', overscrollBehavior: 'contain', overflowX: 'hidden', overflowY: 'auto'}}>
          <List component="nav" className={classes.lateralMenu} style={{ paddingBottom: '500px'}}>
              {
                allUserSolutions.map((row, rowIndex, rowArray) => ( 
                  <ListItem id={`solution-${rowIndex}`} dense button key={row} selected={selectedIndex === rowIndex} style={{ backgroundColor: (selectedIndex !== rowIndex  &&  solutionsStatus[row] === 3) ? '#eeeeee' : '' }} className={classes.lateralMenuItem } onClick={event => handleListItemClick(event, rowIndex)}>
                    <ListItemIcon style={{ width: '38px', minWidth: '38px', maxWidth: '38px' }}>
                      <FolderIcon style={{ color: selectedIndex === rowIndex ? 'white' : 'inherit'}} fontSize="small"></FolderIcon>
                    </ListItemIcon>
                    <ListItemText>
                      <Chip size="small" label={getStatusDescriptionLabel(solutionsStatus[row])}></Chip>&nbsp;&nbsp;&nbsp;<CustomTooltip2 content={getSolutionDescription(row, mainData.origLang)}><span>{ row }</span></CustomTooltip2>
                    </ListItemText>
                    <ListItemSecondaryAction style={{color: 'white'}}>
                      {selectedIndex === rowIndex && <ArrowForwardIosIcon fontSize="small"></ArrowForwardIosIcon>}
                    </ListItemSecondaryAction>
                  </ListItem>
                  ))
                }
            </List>
        </Grid> 
        <Grid item xs={7} style={{ borderTop: '1px solid rgb(224,224,224)',backgroundColor: 'white', height: '100%', overscrollBevaior: 'contain', overflowX: 'hidden', overflowY: 'auto', paddingBottom: '500px'}}>

        <Table className={classes.table} size="small">
      <TableBody>
        {filtered.map((row, rowIndex, rowArray) => (
          <React.Fragment key={row['macroproceso_mostrar'] + "-" + row['proceso_cdv_lv1_mostrar']}>          
          { 
            ((rowIndex > 0 && rowArray[rowIndex -1]['macroproceso_mostrar'] !== row['macroproceso_mostrar']) || (rowIndex === 0)) && 
            (
              <StyledTableRow>
                <StyledTableCell component="th" scope="row" style={{color: 'rgb(22,22,22)'}}>
                  {
                    row['proceso_cdv_lv1_mostrar'] && 
                    (
                      <span style={{paddingLeft: '20px'}}>
                      <strong>{row['macroproceso_mostrar']}</strong>
                      <CustomTooltip2 content={getDescription(row['macroproceso_mostrar'], mainData.lang, true)}>
                        <InfoIcon fontSize="small" style={{ top: '6px', left: '6px', position: 'relative', color: 'rgb(214, 33, 24)' }} />
                      </CustomTooltip2>
                      </span>
                    )
                  }
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: '100px'}}>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: '100px'}}>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: '100px'}}>
                </StyledTableCell>                
              </StyledTableRow>
            )

          }
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" style={{color: 'rgb(22,22,22)'}}>
              {
                row['proceso_cdv_lv1_mostrar'] && 
                (
                <span style={{paddingLeft: '40px'}}>
                  {row['proceso_cdv_lv1_mostrar']}
                  <CustomTooltip2 content={getDescription(row['proceso_cdv_lv1_mostrar'], mainData.lang)}>
                    <InfoIcon fontSize="small" style={{ top: '6px', left: '6px', position: 'relative', color: 'rgb(214, 33, 24)' }} />
                  </CustomTooltip2>
                </span>
                )
              }
              {
                !row['proceso_cdv_lv1_mostrar'] && 
                (
                  <span style={{paddingLeft: '20px'}}>
                  <strong>{row['macroproceso_mostrar']}</strong>
                  <CustomTooltip2 content={getDescription(row['macroproceso_mostrar'], mainData.lang, true)}>
                    <InfoIcon fontSize="small" style={{ top: '6px', left: '6px', position: 'relative', color: 'rgb(214, 33, 24)' }} />
                  </CustomTooltip2>
                  </span>
                )
              }
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '100px'}}>
                {
                    row['no_vida'] > 0 && (
                      <FormControl className={classes.formControl}>
                      <StyledSelect native
                        IconComponent={KeyboardArrowDown}
                        value={results[row['sharedidnovida']]['Field_6566']}                        
                        onChange={handleResultChange(rowIndex, results[row['sharedidnovida']], 'no_vida')}
                        inputProps={{ disabled: readOnly, style: { backgroundColor: readOnly ? '#eeeeee' : null } }}
                        >

                      <option value={0}>{t('NS/NC')}</option>
                      <option value={1}>{t('Inadecuado')}</option>
                      <option value={2}>{t('Limitado')}</option>
                      <option value={3}>{t('Aceptable')}</option>
                      <option value={4}>{t('Adecuado')}</option>
                      <option value={5}>{t('Bueno')}</option>
                    </StyledSelect>
                    </FormControl>
                    )
                }
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '100px'}}>
            {
                    row['vida'] > 0 && (
                      <FormControl className={classes.formControl}>
                      <StyledSelect native
                        IconComponent={KeyboardArrowDown}
                        onChange={handleResultChange(rowIndex, results[row['sharedidvida']], 'vida')}
                        value={results[row['sharedidvida']]['Field_6566']}                        
                        inputProps={{ disabled: readOnly, style: { backgroundColor: readOnly ? '#eeeeee' : null } }}
                      >
                      <option value={0}>{t('NS/NC')}</option>
                      <option value={1}>{t('Inadecuado')}</option>
                      <option value={2}>{t('Limitado')}</option>
                      <option value={3}>{t('Aceptable')}</option>
                      <option value={4}>{t('Adecuado')}</option>
                      <option value={5}>{t('Bueno')}</option>
                    </StyledSelect>
                    </FormControl>
                          )
                }

            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '100px'}}>
            {
                    row['salud'] > 0 && (
                      <FormControl className={classes.formControl}>
                      <StyledSelect native
                        IconComponent={KeyboardArrowDown}
                        onChange={handleResultChange(rowIndex, results[row['sharedidsalud']], 'salud')}
                        value={results[row['sharedidsalud']]['Field_6566']} 
                        inputProps={{ disabled: readOnly, style: { backgroundColor: readOnly ? '#eeeeee' : null } }}
                      >
                      <option value={0}>{t('NS/NC')}</option>
                      <option value={1}>{t('Inadecuado')}</option>
                      <option value={2}>{t('Limitado')}</option>
                      <option value={3}>{t('Aceptable')}</option>
                      <option value={4}>{t('Adecuado')}</option>
                      <option value={5}>{t('Bueno')}</option>
                    </StyledSelect>
                    </FormControl>
                    )
                }

            </StyledTableCell>
          </StyledTableRow>
          </React.Fragment>
        ))}
        <StyledTableRow>
          <StyledTableCell colSpan={4} align="right" style={{ verticalAlign: 'top', paddingTop: '1em', paddingBottom: '1em'}}>
              { !readOnly && (<Fab onClick={() => handleClickOpen()} variant="extended" size="small" style={{ marginLeft: '2em', minWidth: '120px'}}>{ t('Enviar') }</Fab>)}
              { !readOnly &&  (<Fab onClick={() => handleSaveClick(2)}variant="extended" size="small" style={{ marginLeft: '2em', minWidth: '120px', backgroundColor: '#D62118', color: 'white'}}>{ t('Guardar') }</Fab>) }
              { readOnly && !surveyClosed && (<p>{t('No puedes')}</p>)}
              { surveyClosed && (<p>{t('Cerrada')}</p>)}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell colSpan={4}>
                <br/><br/>
                <br/><br/>
                <br/><br/>
                <br/><br/>
                <br/><br/>
          </StyledTableCell>
        </StyledTableRow>
      </TableBody>
    </Table>
    <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Confirmar')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            { t('No') }
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            { t('Enviar') }
          </Button>
        </DialogActions>
      </Dialog>
        </Grid> 
      </Grid>
    </>
  )
  );
}

export default withRouter(Input);
