import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

export default function CustomTooltip({children, content}) {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          {content}
        </React.Fragment>
      }
    >
      {children}
    </HtmlTooltip>
  );
}
