import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

export default function CustomTooltip({children}) {
  const { t, ready } = useTranslation();

  return (
    ( ready && <HtmlTooltip
      title={
        <React.Fragment>
          <Typography style={{ color: '#D62118' }}>{t('Leyenda')}</Typography>
          <p>{t('Ofrece')}</p>
          <ul>
            <li><b>{t('NS/NC')}</b>: {t('NSNCDesc')}</li>
            <li><b>{t('Inadecuado')}</b>: {t('Cubre muy pocas funcionalidades del proceso de Negocio. Es necesario realizar numerosas acciones extras externas o manuales. (1 Punto)')}</li>
            <li><b>{t('Limitado')}</b>: {t('Cubre limitadamente las funcionalidades del proceso de Negocio. Es necesario realiar algunas acciones externas o manuales. (2 Puntos)')}</li>
            <li><b>{t('Aceptable')}</b>: {t('Cubre suficientemente las funcionalidades del proceso de Negocio, pero es necesario realizar alguna acción puntual externa o manual. (3 Puntos)')}</li>
            <li><b>{t('Adecuado')}</b>: {t('Cubre en gran parte las funcionalidades asociadas al proceso de Negocio que requiere la compañia, sin necesidad de acción externa o manual. (4 Puntos)')}</li>
            <li><b>{t('Bueno')}</b>: {t('Cubre totalmente todas las funcionalidaes asociadas al proceso de Negocio que requiere la compañia. (5 Puntos)')}</li>
          </ul>
        </React.Fragment>
      }
    >
      {children}
    </HtmlTooltip> )
  );
}
