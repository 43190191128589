import React from "react";
import Grid from "@material-ui/core/Grid";


export default function Test() { 
    return (
        <Grid container direction="row" justify="center" alignItems="center" style={{backgroundColor: 'grey', height: '100vh'}}>        
            <Grid item xs={12}>
                Cabeceras
            </Grid>
            <Grid item xs={6} style={{backgroundColor: 'blue', height: '100%', overscrollBevaior: 'contain', overflowX: 'hidden', overflowY: 'auto', paddingBottom: '3em'}}>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
                Left 1<br/>
                Left 2<br/>
                Left 3<br/>
                Left 4<br/>
                Left 5<br/>
            </Grid>
            <Grid item xs={6} style={{backgroundColor: 'green', height: '100%', overflowY: 'auto', overscrollBevaior: 'contain', overflowX: 'hidden', paddingBottom: '3em'}}>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
                Right 1<br/>
                Right 2<br/>
                Right 3<br/>
                Right 4<br/>
                Right 5<br/>
            </Grid>
        </Grid>
    )
}
