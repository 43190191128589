import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
.use(initReactI18next)
.init({
    fallbackLng: 'spa',
    debug: false, //process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
        wait: true
    },
    keySeparator: false,
    languages: ['spa', 'eng', 'por'],
    resources: {
        spa: {
            translation: {
                Enviada: 'Enviada',
                'Sin Enviar': 'Sin enviar',
                Guardada: 'Guardada',
                Enviar: 'Enviar',
                Guardar: 'Guardar',
                VIDA: 'VIDA',
                'NO VIDA': 'NO VIDA',
                SALUD: 'SALUD',
                'NS/NC': 'NS/NC',
                Inadecuado: 'Inadecuado',
                Limitado: 'Limitado',
                Aceptable: 'Aceptable',
                Adecuado: 'Adecuado',
                Bueno: 'Bueno',
                Indique: 'Indique el grado con el que la aplicación/solución da cobertura a las funcionalidades del proceso de Negocio y ramo indicado.',
                NSNCDesc: 'No sabe/No contesta',
                Ofrece: 'En cada relación  PROCESO DE NEGOCIO – RAMO que se le presente en la encuesta de cada SOLUCION, deberá elegir del combo una posible respuesta de las que se le ofrece:',
                'Cubre muy pocas funcionalidades del proceso de Negocio. Es necesario realizar numerosas acciones extras externas o manuales. (1 Punto)': 'Cubre muy pocas funcionalidades del proceso de negocio. Es necesario realizar numerosas acciones externas o manuales.',
                'Cubre limitadamente las funcionalidades del proceso de Negocio. Es necesario realiar algunas acciones externas o manuales. (2 Puntos)': 'Cubre limitadamente las  funcionalidades del proceso de negocio. Es necesario realizar algunas acciones externas o manuales.', 
                'Cubre suficientemente las funcionalidades del proceso de Negocio, pero es necesario realizar alguna acción puntual externa o manual. (3 Puntos)': 'Cubre suficientemente las funcionalidades del proceso de negocio, pero es necesario realizar alguna acción puntual externa o manual.', 
                'Cubre en gran parte las funcionalidades asociadas al proceso de Negocio que requiere la compañia, sin necesidad de acción externa o manual. (4 Puntos)': 'Cubre en gran parte las funcionalidades asociadas al proceso de negocio que requiere la compañía, sin necesidad de acción externa o manual.', 
                'Cubre totalmente todas las funcionalidaes asociadas al proceso de Negocio que requiere la compañia. (5 Puntos)': 'Cubre totalmente todas las funcionalidades asociadas al proceso de negocio que requiere la compañía.', 
                Leyenda: 'Leyenda',
                Confirmar: 'Usted va a enviar la encuesta de la solución seleccionada y ya no podrá modificarla. ¿Desea continuar?',
                No: 'No',
                'No puedes': 'La encuesta ha sido enviada y no se puede modificar.',
                Cerrada: 'La encuesta ya no está disponible.'
            }
        },
        eng: {
            translation: {
                Enviada: 'Sent',
                'Sin Enviar': 'Not sent',
                Guardada: 'Saved',
                Enviar: 'Send',
                Guardar: 'Save',
                VIDA: 'LIFE',
                'NO VIDA': 'NO LIFE',
                SALUD: 'HEALTH',
                'NS/NC': 'DK/NA',
                Inadecuado: 'Inadequate',
                Limitado: 'Limited',
                Aceptable: 'Acceptable',
                Adecuado: 'Adequate',
                Bueno: 'Good',
                Indique: 'Indicate the degree to which the application / solution covers the functionalities of the Business processes and indicated line.',
                NSNCDesc: 'Don’t know/Don’t answer',
                Ofrece: 'In each relationship BUSINESS PROCESS - LINE that is presented in the survey of each SOLUTION, you should choose from the combo a possible response from those offered:',
                'Cubre muy pocas funcionalidades del proceso de Negocio. Es necesario realizar numerosas acciones extras externas o manuales. (1 Punto)': 'It covers very few functionalities of the business process. It is necessary to perform numerous external or manual actions.',
                'Cubre limitadamente las funcionalidades del proceso de Negocio. Es necesario realiar algunas acciones externas o manuales. (2 Puntos)': 'Limitedly covers the functionalities of the business process. It is necessary to perform some external or manual actions.', 
                'Cubre suficientemente las funcionalidades del proceso de Negocio, pero es necesario realizar alguna acción puntual externa o manual. (3 Puntos)': ' It sufficiently covers the functionalities of the business process, but it is necessary to perform some specific external or manual action.', 
                'Cubre en gran parte las funcionalidades asociadas al proceso de Negocio que requiere la compañia, sin necesidad de acción externa o manual. (4 Puntos)': ' It largely covers the functionalities associated with the business process required by the company, without the need for external or manual action.', 
                'Cubre totalmente todas las funcionalidaes asociadas al proceso de Negocio que requiere la compañia. (5 Puntos)': 'It completely covers all the functionalities associated with the business process that the company requires.', 
                Leyenda: 'Legend',
                Confirmar: 'You will send the survey of the selected solution and you will no longer be able to modify it. Do you wish to continue?',
                No: 'Cancel',
                'No puedes': 'The survey has been sent and cannot be modified.',
                Cerrada: 'The survey is no longer available.'

            }
        },
        por: {
            translation: {
                Enviada: 'Enviada',
                'Sin Enviar': 'Sem enviar',
                Guardada: 'Guardada',
                Enviar: 'Enviar',
                Guardar: 'Guardar',
                VIDA: 'VIDA',
                'NO VIDA': 'NÃO VIDA',
                SALUD: 'SAÚDE',
                'NS/NC': 'NS/NR',
                Inadecuado: 'Inadequado',
                Limitado: 'Limitado',
                Aceptable: 'Aceitável',
                Adecuado: 'Adequado',
                Bueno: 'Bom',
                Indique: 'Indique o grau em que o aplicativo / solução cobre as funcionalidades dos processos de negócios e ramo indicado.',
                NSNCDesc: 'Não sabe/Não responde',
                Ofrece: 'Em cada relacionamento de "PROCESSO DE NEGÓCIOS e RAMO" apresentado na pesquisa de cada APLICAÇÃO / SOLUÇÃO, você deve escolher uma resposta:',
                'Cubre muy pocas funcionalidades del proceso de Negocio. Es necesario realizar numerosas acciones extras externas o manuales. (1 Punto)': 'abrange poucas funcionalidades do processo de negócios. É necessário executar várias ações externas ou manuais.',
                'Cubre limitadamente las funcionalidades del proceso de Negocio. Es necesario realiar algunas acciones externas o manuales. (2 Puntos)': 'cobre de forma limitada as funcionalidades do processo de negócios. É necessário executar algumas ações externas ou manuais.', 
                'Cubre suficientemente las funcionalidades del proceso de Negocio, pero es necesario realizar alguna acción puntual externa o manual. (3 Puntos)': 'abrange suficientemente as funcionalidades do processo de negócios, mas é necessário executar poucas ações externas ou manuais, de forma específica.', 
                'Cubre en gran parte las funcionalidades asociadas al proceso de Negocio que requiere la compañia, sin necesidad de acción externa o manual. (4 Puntos)': 'abrange amplamente as funcionalidades associadas ao processo de negócios exigido pela empresa, sem a necessidade de ação externa ou manual.', 
                'Cubre totalmente todas las funcionalidaes asociadas al proceso de Negocio que requiere la compañia. (5 Puntos)': 'abrange completamente todas as funcionalidades associadas ao processo de negócios exigidos pela empresa.', 
                Leyenda: 'Legenda',
                Confirmar: 'Você enviará a pesquisa da solução selecionada e não poderá mais modificá-la. Deseja continuar?',
                No: 'Não',
                'No puedes': 'A pesquisa foi enviada e não pode ser modificada.',
                Cerrada: 'A pesquisa não está mais disponível.'

            }
        },

    }
});

export default i18n;